// Function to disable scrolling
export const disableBodyScroll = () => {
    // Listen for wheel, touchmove, and keydown (for arrow keys)
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });
    window.addEventListener('keydown', preventKeyScroll, { passive: false });
};

// Function to enable scrolling
export const enableBodyScroll = () => {
    // Remove event listeners for scroll prevention
    window.removeEventListener('wheel', preventScroll);
    window.removeEventListener('touchmove', preventScroll);
    window.removeEventListener('keydown', preventKeyScroll);
};

// Prevent default behavior for scrolling
const preventScroll = (e) => {
    e.preventDefault();
};

// Prevent default behavior for key presses (arrow keys and space)
const preventKeyScroll = (e) => {
    // Check if the key pressed is an arrow key or space bar
    if ([32, 33, 34, 35, 36, 37, 38, 39, 40].includes(e.keyCode)) {
        e.preventDefault();
    }
};
