import React from "react";
import { HashRouter as Router, Route, Routes, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';

function NavTabs() {
    return (
        <nav className="">
            <div className="row g-0">
                <NavLink to="/following" className={({ isActive, isPending, isTransitioning }) =>
                    [
                    "col btn top py-3 text-center w-100",
                    isPending ? "pending" : "",
                    isActive ? "active" : "",
                    isTransitioning ? "transitioning" : "",
                    ].join(" ")
                }>
                    Du folgst 
                </NavLink>
                <NavLink to="/" className={({ isActive, isPending, isTransitioning }) =>
                    [
                    "col btn top py-3 text-center w-100",
                    isPending ? "pending" : "",
                    isActive ? "active" : "",
                    isTransitioning ? "transitioning" : "",
                    ].join(" ")
                }>
                Alle
                </NavLink>
            </div>
        </nav>
    );
}
export default NavTabs;
