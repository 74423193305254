import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from "react";
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';

import './MainMenu.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'helpers/scrollLock';

const MainMenu = forwardRef(({ menuItems, className, toggleButtonRef }, ref) => {
    const mainMenuContainer = useRef(null);
    const [open, setOpen] = useState(false);

    // Ref, um den aktuellen Wert von open zu speichern
    const openRef = useRef(open);

    // Aktualisieren des openRef, wenn open sich ändert
    useEffect(() => {
        openRef.current = open;
    }, [open]);

    // Methoden für den Elternkomponenten verfügbar machen
    useImperativeHandle(ref, () => ({
        openMenu,
        closeMenu,
        toggleMenu,
    }));

    // Animation der Menü-Höhe
    const animateMenuHeight = useCallback((action) => {
        const menu = mainMenuContainer.current;
        if (!menu) return;

        const maxHeight = menu.scrollHeight;
        let startHeight = action === 'open' ? 0 : maxHeight;
        let endHeight = action === 'open' ? maxHeight : 0;
        let startTime = null;

        const duration = 300; // Dauer in Millisekunden

        // Korrekte Ease-In-Out-Quadratic-Funktion
        const easeInOutQuad = (t) => {
            return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
        };

        const animate = (timestamp) => {
            if (!startTime) startTime = timestamp;
            const elapsed = timestamp - startTime;
            const progress = Math.min(elapsed / duration, 1);
            const easedProgress = easeInOutQuad(progress);
            const currentHeight = startHeight + (endHeight - startHeight) * easedProgress;

            menu.style.height = `${currentHeight}px`;

            if (elapsed < duration) {
                requestAnimationFrame(animate);
            } else {
                // Setzen Sie die Höhe auf 'auto' nach dem Öffnen und auf '0' nach dem Schließen
                menu.style.height = action === 'open' ? 'auto' : '0';
                if (action === 'close') {
                    setOpen(false);
                    // Reset transform nach dem Schließen
                    menu.style.transform = 'translateY(0)';
                }
            }
        };

        requestAnimationFrame(animate);
    }, []);

    // Menü öffnen
    const openMenu = useCallback(() => {
        const menu = mainMenuContainer.current;
        if (menu) {
            // Transform zurücksetzen, um sicherzustellen, dass das Menü vollständig sichtbar ist
            menu.style.transform = 'translateY(0)';
            menu.style.transition = '';
        }
        setOpen(true);
        animateMenuHeight('open');
        disableBodyScroll(); // Scrollen deaktivieren
    }, [animateMenuHeight]);

    // Menü schließen
    const closeMenu = useCallback(() => {
        console.log('closeMenu aufgerufen');
        animateMenuHeight('close');
        enableBodyScroll(); // Scrollen wieder aktivieren
    }, [animateMenuHeight]);

    // Menü umschalten
    const toggleMenu = useCallback(() => {
        if (open) {
            closeMenu();
        } else {
            openMenu();
        }
    }, [open, openMenu, closeMenu]);

    // Touch gesture handling for swipe down to close
    const touchStartY = useRef(null);
    const touchCurrentY = useRef(null);
    const touchThreshold = 0.2; // 20% der Menühöhe

    const handleTouchStart = useCallback((e) => {
        if (!openRef.current) return;
        touchStartY.current = e.touches[0].clientY;
        touchCurrentY.current = e.touches[0].clientY;
    }, []);

    const handleTouchMove = useCallback((e) => {
        if (!openRef.current || touchStartY.current === null) return;
        touchCurrentY.current = e.touches[0].clientY;
        const deltaY = touchCurrentY.current - touchStartY.current;
        if (deltaY > 0) { // Nur nach unten wischen
            e.preventDefault(); // Verhindert Scrollen
            const menu = mainMenuContainer.current;
            if (menu) {
                const translateY = deltaY;
                menu.style.transform = `translateY(${translateY}px)`;
            }
        }
    }, []);

    const handleTouchEnd = useCallback((e) => {
        if (!openRef.current || touchStartY.current === null || touchCurrentY.current === null) return;
        const deltaY = touchCurrentY.current - touchStartY.current;
        const menu = mainMenuContainer.current;
        if (menu) {
            const menuHeight = menu.scrollHeight;
            const threshold = menuHeight * touchThreshold;
            if (deltaY > threshold) {
                closeMenu();
            } else {
                // Rückkehr zur ursprünglichen Position
                menu.style.transition = 'transform 0.3s ease-out';
                menu.style.transform = 'translateY(0)';
                // Entfernen der Transition nach der Animation
                const handleTransitionEnd = () => {
                    menu.style.transition = '';
                    menu.removeEventListener('transitionend', handleTransitionEnd);
                };
                menu.addEventListener('transitionend', handleTransitionEnd);
            }
        }
        touchStartY.current = null;
        touchCurrentY.current = null;
    }, [closeMenu, touchThreshold]);

    // Menü schließen, wenn außerhalb geklickt wird
    const handleClickOutside = useCallback((event) => {
        console.log("open", openRef.current);
        if (
            openRef.current && // Überprüfung des aktuellen Werts von open
            mainMenuContainer.current &&
            !mainMenuContainer.current.contains(event.target) &&
            (!toggleButtonRef?.current || !toggleButtonRef.current.contains(event.target))
        ) {
            closeMenu();
        }
    }, [closeMenu, toggleButtonRef]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const menuContent = (
        <>
            {/* Backdrop */}
            {open && (
                <div className={`backdrop ${open ? 'active' : ''}`} onClick={closeMenu}></div>
            )}

            {/* Hauptmenü-Container */}
            <div
                className={`main-menu-container ${open ? 'active' : ''} ${className || ''}`}
                ref={mainMenuContainer}
                role="menu"
                aria-hidden={!open}
            >
                <div
                    className="main-menu-content"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {/* Menüinhalt */}
                    <nav className="main-menu">
                        {menuItems.map((item, index) => (
                            <NavLink
                                key={index}
                                to={item.path}
                                onClick={open ? closeMenu : undefined} // Nur wenn geöffnet
                                className={({ isActive }) => (isActive ? "active" : "")}
                            >
                                {item.label}
                            </NavLink>
                        ))}
                    </nav>
                    {open && (
                        <button className="ra-btn icon-button main-menu-close-button" onClick={closeMenu}>
                            <FontAwesomeIcon icon={faXmark} size="2x" />
                        </button>
                    )}
                </div>
            </div>
        </>
    );

    return ReactDOM.createPortal(
        menuContent,
        document.body
    );
});

MainMenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    className: PropTypes.string,
    toggleButtonRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
};

export default MainMenu;