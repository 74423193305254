import React, { useState, useEffect, useRef } from 'react';
import { get } from 'API';
import FeedItem from "components/feed/FeedItemNew/FeedItemNew";
import NavTabs from "components/navs/NavTabs";
import NewPost from "components/feed/NewPost";

function Feed() {
    const [resultData, setResultData] = useState([]);
    const [newPosts, setNewPosts] = useState([]);
    const endpoint = 'contents';

    // Use a ref to hold the current resultData without causing re-renders
    const resultDataRef = useRef(resultData);

    // Update the ref whenever resultData changes
    useEffect(() => {
        resultDataRef.current = resultData;
    }, [resultData]);

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            get(endpoint)
                .then(data => {
                    const prevData = resultDataRef.current;

                    if (prevData.length > 0) {
                        // Assuming posts are sorted with newest first
                        const existingIds = new Set(prevData.map(post => post._id));
                        const freshPosts = data.filter(post => !existingIds.has(post._id));

                        if (freshPosts.length > 0) {
                            // Store new posts without displaying them
                            setNewPosts(freshPosts);
                        }
                    } else {
                        // Initial load, set the resultData
                        setResultData(data);
                    }
                })
                .catch(error => console.error('Error fetching data:', error));
        };

        // Initial data fetch
        fetchData();

        // // Set up an interval to fetch data every 5 seconds
        // const intervalId = setInterval(fetchData, 5000);

        // // Clean up the interval on component unmount
        // return () => clearInterval(intervalId);
    }, [endpoint]);

    // Function to display new posts when the button is clicked
    const handleShowNewPosts = () => {
        setResultData(prevData => [...newPosts, ...prevData]); // Prepend new posts
        setNewPosts([]); // Clear newPosts
    };

    // Function to reload a specific item by ID
    const reloadItem = async (itemId) => {
        try {
            // Fetch the updated item from the API
            const updatedItem = await get(`${endpoint}/${itemId}`);

            // Update only the specific item in the resultData array
            setResultData(prevData =>
                prevData.map(item => (item._id === updatedItem._id ? updatedItem : item))
            );
        } catch (error) {
            console.error('Error reloading item:', error);
        }
    };

    // Function to add the new post to the top of the timeline
    const addNewPost = (newPost) => {
        setResultData(prevData => [newPost, ...prevData]); // Prepend the new post
    };

    return (
        <>
            <NavTabs />
            <NewPost addNewPost={addNewPost} />
            {newPosts.length > 0 && (
                <button className="btn btn-primary border-0 border-width-2 border-bottom border-gray-1 w-100" onClick={handleShowNewPosts}>
                    ({newPosts.length} neu) Tippe um anzuzeigen
                </button>
                
            )}
            <div className="px-3">
                {resultData.map((item, index) => (
                    <FeedItem key={index} item={item} />
                ))}
            </div>
        </>
    );
}

export default Feed;
