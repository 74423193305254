import React from "react";
import ButtonLike from "./../../components/feed/ButtonLike.jsx";
import ButtonComment from "./../../components/feed/ButtonComment.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane as farFaPaperPlane } from '@fortawesome/free-regular-svg-icons';

function FeedItemInteractions({ item, reloadItem, parentType }) {
    return (
        <div className="row align-items-center gx-0 mt-2">
            <div className="col-auto">
                <ButtonLike item={item} reloadItem={reloadItem} parentType={parentType} />
            </div>
            <div className="col-auto">
                <ButtonComment item={item} reloadItem={reloadItem} />
            </div>
            <div className="col-auto">
                <button className="btn rounded-pill px-2 text-secondary">
                    <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={farFaPaperPlane} size="1x" className="me-1" />
                        <span className="text-xs">{item.reposts_count}</span>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default FeedItemInteractions;
