import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeAPIRequest } from './../API.jsx';

const Verify = () => {
    const [formData, setFormData] = useState({
        email: '',
        token: ''
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await makeAPIRequest('register/verify', 'POST', formData);
            alert('Verification successful! You can now log in.');
            navigate('/login');
        } catch (error) {
            alert('Verification failed: ' + error.message);
        }
    };

    return (
        <div>
            <h2>Verify Account</h2>
            <form onSubmit={handleSubmit}>
                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                <input type="text" name="token" placeholder="Verification Token" value={formData.token} onChange={handleChange} required />
                <button type="submit">Verify</button>
            </form>
        </div>
    );
};

export default Verify;