import React, { useState, useEffect } from 'react';
import { get } from './../API.jsx';


function Search() {
    const [resultData, setResultData] = useState([]);
    const endpoint = 'contents';

    useEffect(() => {
        get(endpoint).then(data => {
            setResultData(data);
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <>
            {resultData.map((item, index) => {
                return (
                    <div className="row gx-0 p-3 border-bottom border-gray-1">
                        <div className="col-auto">
                            Search
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default Search;