import React, { useRef, useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import 'assets/bootstrap/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import 'assets/css/core.css';
import 'App.css';
import 'assets/css/index.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Docs from 'pages/Docs';
import MainMenu from "components/navs/MainMenu/MainMenu";

import Feed from 'pages/Feed/Feed';
import Search from 'pages/Search';
import User from 'pages/User';
import Post from 'pages/Post';
import Comment from 'pages/Comment';
import Welcome from 'pages/Welcome/Welcome';
import Login from 'pages/Login/Login';
import Logout from 'pages/Logout/Logout';
import Register from 'pages/Register/Register';
import Verify from 'pages/Verify';
import ProtectedRoute from 'components/ProtectedRoute';
import { getUserToken } from './API';

function App() {
    const mainMenuRef = useRef(null);
    const mainMenuToggleButtonRef = useRef(null);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const token = getUserToken();
        setIsAuthenticated(!!token);
    }, []);
    const MainMenuItems = [
        { path: "/", label: "Startseite" },
        { path: "/following", label: "Folge ich" },
        { path: "/user", label: "Mein Bereich" },
        { path: "/docs", label: "Dokumentation" },
        { path: "/logout", label: "Abmelden" },
    ];

    const openMainMenu = () => {
        if (mainMenuRef.current) {
            mainMenuRef.current.openMenu();
        }
    };

    return (
        <Router>
            <meta name="viewport" content="width=device-width, user-scalable=no" />
            <div className="app-container border-gray-1" data-bs-theme="dark">
                <div className="w-100">
                    <Routes>
                        <Route path="/welcome" element={<Welcome />} />
                        <Route path="/login" element={<Login onLogin={() => setIsAuthenticated(true)} />} />
                        <Route path="/logout" element={<Logout onLogout={() => setIsAuthenticated(false)} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/verify" element={<Verify />} />
                        <Route path="/docs" element={<ProtectedRoute element={Docs} />} />
                        <Route path="/" element={<ProtectedRoute element={Feed} />} />
                        <Route path="/following" element={<ProtectedRoute element={Feed} />} />
                        <Route path="/search" element={<ProtectedRoute element={Search} />} />
                        <Route path="/user" element={<ProtectedRoute element={User} />} />
                        <Route path="/post/*" element={<ProtectedRoute element={Post} />} />
                        <Route path="/comment/*" element={<ProtectedRoute element={Comment} />} />
                    </Routes>

                    <MainMenu
                        ref={mainMenuRef}
                        menuItems={MainMenuItems}
                        toggleButtonRef={mainMenuToggleButtonRef}
                    />

                    {isAuthenticated && (
                        <button
                            className="ra-btn icon-button main-menu-button"
                            ref={mainMenuToggleButtonRef}
                            onClick={openMainMenu}
                        >
                            <FontAwesomeIcon icon={faBars} size="2x" />
                        </button>
                    )}
                </div>
            </div>
        </Router>
    );
}

export default App;