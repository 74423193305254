import Cookies from 'js-cookie';

// const userToken = '$2y$12$ZGc1oiZBdNx3eaci2mleZepNLIDeZu8pl4y86WCirAdzl5eSdONtu';
// const userID = '67017db09bf0cb425809e154';
// const userToken = '$2y$12$zBiYh6U5TYKxdfqVVMUcR.JybEv9vcJNnpucPFUcEMH3PFNJnRk/e';
// const userID = '670c4cc784aafb322e0baead';

// await setUserToken('$2y$12$zBiYh6U5TYKxdfqVVMUcR.JybEv9vcJNnpucPFUcEMH3PFNJnRk/e');
// await setUserID('670c4cc784aafb322e0baead');
// api.js

// Import electron-store
const serverFeedUrl = 'https://api.risingarrow.de/api';

// Functions to get and set user data using the exposed electronStore API
export function getUserToken() {
    if(window.electronStore){
        const token = window.electronStore.get('user_token');
        // console.log(`Renderer process: Retrieved user_token 123: ${token}`);
        return token;
    } else {
        const token = Cookies.get('user_token');
        // console.log(`Renderer process: Retrieved user_token 123: ${token}`);
        return token;
    }
    
}

export function getUserID() {
    if(window.electronStore){
        const user_id = window.electronStore.get('user_id');
        // console.log(`Renderer process: Retrieved user_id 123: ${user_id}`);
        return user_id;
    } else {
        const user_id = Cookies.get('user_id');
        // console.log(`Renderer process: Retrieved user_id 123: ${user_id}`);
        return user_id;
    }
}

export function setUserToken(token) {
    if(window.electronStore){
        window.electronStore.set('user_token', token);
    } else {
        Cookies.set('user_token', token);
    }
}

export function setUserID(id) {
    if(window.electronStore){
        window.electronStore.set('user_id', id);
    } else {
        Cookies.set('user_id', id);
    }
}

export function clearUserData() {
    if(window.electronStore){
        window.electronStore.delete('user_token');
        window.electronStore.delete('user_id');
    } else {
        Cookies.remove('user_token');
        Cookies.remove('user_id');
    }
}

// Function to get user information
export async function user() {
    try {
        const result = await makeAPIRequest('users/me', 'GET');
        return result.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        return [];
    }
}

// Function to make GET requests
export async function get(endpoint) {
    try {
        const result = await makeAPIRequest(endpoint, 'GET');
        return result.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
}

// Function to save data (POST or PUT)
export async function save(endpoint, data, id = null) {
    try {
        let url = endpoint;
        const method = id ? 'PUT' : 'POST';
        if (id) {
            url += `/${id}`;
        }
        const result = await makeAPIRequest(url, method, data);
        return result.data;
    } catch (error) {
        console.error('Error saving data:', error);
        throw error;
    }
}

// Function to delete data
export async function remove(endpoint) {
    try {
        const result = await makeAPIRequest(endpoint, 'DELETE');
        return result;
    } catch (error) {
        console.error('Error deleting data:', error);
        throw error;
    }
}

// Core function to make API requests
export async function makeAPIRequest(endpoint, method, data = null) {
    const userToken = await getUserToken();
    const userID = await getUserID();
    try {
        const options = {
            method: method,
            headers: {
                'Authorization': 'Bearer ' + userToken,
                'User': userID,
                'Content-Type': 'application/json',
            },
        };

        if (method !== 'GET' && data) {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(`${serverFeedUrl}/${endpoint}`, options);
        const result = await response.json();

        if (!response.ok) {
            const error = new Error(result.error || result.status?.message || "API request failed");
            error.response = result;
            throw error;
        }

        if (result.success === false) {
            throw new Error(result.message || 'API returned an error.');
        }

        return result;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}


