import React from 'react';

// Components
import FeedItem from '../components/feed/FeedItemNew/FeedItemNew.jsx';
import Button from '../components/buttons/Button/Button.jsx';

function Docs() {
    var img = [
        "https://images.cgames.de/images/gsgp/226/the-legend-of-zelda-tears-of-the-kingdom_6225495.jpg", 
        "https://www.swr.de/swrkultur/leben-und-gesellschaft/1713344603909%2Cthe-legend-of-zelda-108~_v-16x9@2dL_-6c42aff4e68b43c7868c3240d3ebfa29867457da.png",
        "https://www.schauspielervideos.de/FM/fotos/15/99015656/kf1810842_gr_1800.jpg",
        "https://media.zenfs.com/de/spotonnews.de.com/75112e0f97d403926f8df7da6bb424fd",
        "https://i.ytimg.com/vi/hoULqXz-oEU/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAXWFu8E_XXuuShGaREs46b7cJP-Q",
        "https://gamersonly.com/cdn/shop/files/Partner_01-min_Kopie.jpg?v=1684866095",
        "https://i.ytimg.com/vi/j07i07hlzn4/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLCdB0iTF1WaCeEBrdh7q2ncUZOJOw",
        "https://www.gameswirtschaft.de/wp-content/uploads/2024/10/Friendly-Fire-10-Gruppenfoto-141024.jpg"
    ];

    var images = [];

    // Deduplicate images
    img.forEach(element => {
        if (images.indexOf(element) === -1) {
            images.push(element);
        }
    });

    // Shuffle function using Fisher-Yates algorithm
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
    }

    // Shuffle images array
    shuffleArray(images);

    return (
        <div className="px-3">
            <h1>Dokumentation</h1>
            <section className="mb-5">
                <h2>Feed Item</h2>
                {images.map((element, index) => <FeedItem item={[]} key={index} img={element} />)}
            </section>
            <section>
                <h2>Buttons</h2>
                <p>
                    Diese Dokumentation zeigt die verschiedenen Varianten von Buttons und ihre Verwendungszwecke. 
                    Jeder Button simuliert eine zufällige Antwort (Erfolg oder Fehler) nach einem Klick.
                </p>
                
                <div className="row g-3 justify-content-center align-items-center">
                    {/* Button Beispiele */}
                    {/* Kleiner Button */}
                    <div className="col-6">
                        <Button 
                            label="Absenden" 
                            type="info" 
                            size="small" 
                            ghost={true} 
                            errorMsg="Die Daten konnten nicht gespeichert werden!" 
                            successMsg="Die Daten wurden erfolgreich gespeichert!"
                            clickTrigger={() => console.log("Triggered")} 
                            clickResponse={() => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Math.random() > 0.5) {
                                            resolve(); // Erfolg
                                        } else {
                                            reject(new Error("Random error occurred")); // Fehler
                                        }
                                    }, 3000);
                                })
                            }
                        />
                    </div>
                    <div className="col-6">
                        <p>
                            <strong>Kleiner Button</strong>: Diese Variante wird verwendet, wenn wenig Platz zur Verfügung steht oder 
                            der Button eine sekundäre Funktion hat. Er zeigt eine kleinere Version eines typischen Buttons an.
                        </p>
                    </div>

                    {/* Normaler Button */}
                    <div className="col-6">
                        <Button 
                            label="Absenden" 
                            type="warning" 
                            ghost={true}
                            errorMsg="Die Daten konnten nicht gespeichert werden!" 
                            successMsg="Die Daten wurden erfolgreich gespeichert!"
                            clickTrigger={() => console.log("Triggered")} 
                            clickResponse={() => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Math.random() > 0.5) {
                                            resolve(); // Erfolg
                                        } else {
                                            reject(new Error("Random error occurred")); // Fehler
                                        }
                                    }, 3000);
                                })
                            }
                        />
                    </div>
                    <div className="col-6">
                        <p>
                            <strong>Normaler Button</strong>: Dies ist der Standard-Button für allgemeine Aktionen. Er wird verwendet, 
                            wenn der Platz ausreichend ist und der Button eine Hauptaktion darstellt.
                        </p>
                    </div>

                    {/* Großer Button */}
                    <div className="col-6">
                        <Button 
                            label="Großer Button" 
                            type="primary" 
                            size="big" 
                            ghost={true} 
                            errorMsg="Die Daten konnten nicht gespeichert werden!" 
                            successMsg="Die Daten wurden erfolgreich gespeichert!"
                            clickTrigger={() => console.log("Triggered")} 
                            clickResponse={() => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        if (Math.random() > 0.5) {
                                            resolve(); // Erfolg
                                        } else {
                                            reject(new Error("Random error occurred")); // Fehler
                                        }
                                    }, 3000);
                                })
                            }
                        />
                    </div>
                    <div className="col-6">
                        <p>
                            <strong>Großer Button</strong>: Diese Variante wird für hervorgehobene Aktionen verwendet, die die Aufmerksamkeit 
                            des Nutzers auf sich ziehen sollen. Der große Button ist ideal für primäre Aktionen, wie z.B. das Speichern von Daten.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Docs;
