import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment as fasFaComment } from '@fortawesome/free-solid-svg-icons';
import { faComment as farFaComment } from '@fortawesome/free-regular-svg-icons';

function ButtonComment({ item, reloadItem, loadCommentSection }) {
    const [commented, setCommented] = useState(false);
    const [commentInteractionId, setCommentInteractionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setCommented(item.commented_by_current_user || false);
        setCommentInteractionId(item.comment_interaction_id || null);
    }, [item]);

    const handleClick = async () => {
        setLoading(true);
        setError(null);
        try {
            if (loadCommentSection) loadCommentSection();
            if (reloadItem) reloadItem(item._id);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <button className="btn rounded-pill px-2 me-1 text-secondary" onClick={handleClick} disabled={loading}>
            <div className={`d-flex align-items-center ${commented ? 'text-primary' : 'text-secondary'}`}>
                <FontAwesomeIcon icon={commented ? fasFaComment : farFaComment} size="1x" className="me-1" />
                <span className="text-xs">{item.comments_count || item.replies_count}</span>
            </div>
        </button>
    );
}

export default ButtonComment;
