import React from "react";

import './FeedItem.css';

//Moment.js
import moment from 'moment';
import 'moment/locale/de';

// Fontawesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faHeart as fasFaHeart,
    faComment as fasFaComment 
} from '@fortawesome/free-solid-svg-icons';

function FeedItem({ item }) {
    return (
        <div className="feed-item">
            <div className="bg">
                <img src={item.media?.src} alt="" />
            </div>
            <div className="content">
                <div className="d-flex">
                    <div className="left">
                        <div className="ratio ratio-1x1 mb-3">
                            <img src={`https://i.pravatar.cc/300?${Math.random()}`} alt="" />
                        </div>
                        <div className="mb-3">
                            <FontAwesomeIcon className="icon" icon={fasFaHeart} size="1x" /><br />
                            <span className="ml-1">{item.likes_count || 0}</span>
                        </div>
                        <div>
                            <FontAwesomeIcon className="icon" icon={fasFaComment} size="1x" /><br />
                            <span className="ml-1">{item.comments_count || 0}</span>
                        </div>
                    </div>
                    <div className="right">
                        <div className="d-flex mb-3">
                            <div className="username">{item.user?.name}</div>
                            <div className="time-since">{moment(item.created_at).fromNow()}</div>
                        </div>
                        <div>
                            <p>
                                {item.content}
                            </p>
                        </div> 
                        <div>
                            <div className="media">
                                <img src={item.media?.src} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeedItem;
