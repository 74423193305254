import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { get } from './../API.jsx';

import FeedItem from "./../components/feed/FeedItem.jsx";
import NewComment from "./../components/feed/NewComment.jsx";
import FeedItemAvatar from "./../components/feed/FeedItemAvatar.jsx";
import FeedItemHeader from "./../components/feed/FeedItemHeader.jsx";
import FeedItemInteractions from "./../components/feed/FeedItemInteractions.jsx";

function Comment() {
  const { "*": commentID } = useParams();

  const endpoint = `comments/${commentID}`;

  const [commentData, setCommentData] = useState({});
  const [repliesData, setRepliesData] = useState([]);

  useEffect(() => {
    if (commentID) {
      get(endpoint)
        .then(data => {
          setCommentData(data);
          setRepliesData(data.replies || []);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [commentID, endpoint]);

    return (
        <>
            <FeedItem item={commentData} type="comment"/><div>
            <div className="row gx-0">
                <div className="col-auto">
                    <div className="d-flex justify-content-center px-3">
                        <div style={{ width: '40px' }}></div>
                    </div>
                    <div className="h-100">
                        <div className="d-flex justify-content-center h-100"></div>
                    </div>
                </div>
                <div className="col">
                </div>
            </div>
            <div className="p-3 border-bottom border-1 border-gray-1 fw-medium">Antworten</div>
            {repliesData.map((comment, index, comments) => (
                <div className="cursor-pointer" key={comment._id || index}>
                    <div className="row gx-0 bg-gray-2">
                        <div className="col-auto">
                            <div className="d-flex justify-content-center px-3">
                                <div style={{ width: '40px' }}></div>
                            </div>
                            <div className="h-100">
                                <div className="d-flex flex-column align-items-center h-100">
                                    <div className="py-3">
                                        <FeedItemAvatar item={comment} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row gx-0 py-3">
                                <div className="col">
                                    <FeedItemHeader item={comment} type={"comment"} />
                                    {comment.content}
                                    <FeedItemInteractions
                                        item={comment}
                                        parentType="comment"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </>
    );
}

export default Comment;