import React, { useContext, useState, useRef } from "react";
import Button from '../buttons/Button/Button.jsx';
import { UserContext } from './../../contexts/UserContext';
import { save } from './../../API.jsx';
import DOMPurify from 'dompurify';

function NewPost({ addNewPost }) {
    const { userData } = useContext(UserContext);
    const contentEditableRef = useRef(null);
    const [content, setContent] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    const handleInput = () => {
        const htmlContent = contentEditableRef.current.innerHTML;
        setContent(htmlContent);
    };

    const handleSave = async () => {
        setLoading(true);
        setErrorMsg(null);
        setSuccessMsg(null);
        
        try {
            const sanitizedContent = DOMPurify.sanitize(content);
            const response = await save('contents', { type: 'post', content: sanitizedContent });

            if (response) {
                setContent('');
                contentEditableRef.current.innerHTML = '';
                setSuccessMsg('Post erfolgreich erstellt!');
                addNewPost(response);
            }
        } catch (error) {
            setErrorMsg('Post konnte nicht abgeschickt werden!');
            throw error; // Wirft den Fehler weiter, damit `clickResponse` weiß, dass es fehlschlägt
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="row gx-0 border-bottom border-gray-1">
            <div className="col">
                <form className="border-top border-bottom border-1 border-gray-1" onSubmit={(e) => e.preventDefault()}>
                    <div className="d-flex flex-column">
                        <div className="col">
                            <div className="border-bottom border-1 border-gray-1 d-flex py-3">
                                <div className="d-flex justify-content-center px-3">
                                    <div style={{ width: '40px' }}>
                                        <img
                                            src={"https://i.pravatar.cc/300?" + userData._id}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div>{userData.name}</div>
                                    <div
                                        id="content"
                                        className="form-control"
                                        contentEditable
                                        placeholder="Was steht an?"
                                        onInput={handleInput}
                                        ref={contentEditableRef}
                                        style={{ minHeight: '100px' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col p-3">
                            <div className="row justify-content-end align-items-center">
                                <div className="col-auto">
                                    <Button 
                                        label="Post speichern" 
                                        type="primary" 
                                        size="small" 
                                        ghost={true} 
                                        errorMsg={errorMsg}
                                        // successMsg={successMsg}
                                        clickTrigger={() => {}}
                                        clickResponse={() => handleSave()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewPost;
