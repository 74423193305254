import React, { useContext } from "react";
import { UserContext } from './../../contexts/UserContext';

function FeedItemAvatar({ isOpen, isAnimating, item }) {
    const { userData } = useContext(UserContext);

    return (
        <>
            <div className="d-flex justify-content-center px-3">
                <div style={{ width: '40px' }}>
                    <img
                        src={"https://i.pravatar.cc/300?" + item.user?._id}
                        alt={item.user?.name}
                        className="img-fluid rounded-circle"
                    />
                </div>
            </div>
            {(isOpen || isAnimating) ? (
                <div style={{ height: 'calc(100% - 1rem)' }}>
                    <div className="d-flex justify-content-center py-3 h-100">
                        {/* <div className="border border-1 h-100"></div> */}
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default FeedItemAvatar;
