import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

import './Button.css';

function Button({ isLink, label, type, size = '', width = null, clickTrigger, clickResponse, ghost, callback }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [currentType, setCurrentType] = useState(type);
    const [active, setActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const calculateTimeout = (message) => {
        const baseTimeout = 3000; // Base timeout in milliseconds
        const extraTimePerChar = 30; // Extra time per character in milliseconds
        return baseTimeout + (message ? message.length * extraTimePerChar : 0);
    };

    const handleClick = async () => {
        setActive(true);
        setLoading(true);
        setSuccess(false);
        setError(null);
        setSuccessMessage(null);
        setCurrentType(type);

        try {
            clickTrigger && clickTrigger();
            const successMsg = await clickResponse(false);
            setSuccessMessage(successMsg);
            setSuccess(true);
            setCurrentType('success');
            setTimeout(() => {
                setSuccess(false);
                setSuccessMessage(null);
                setActive(false);
                callback && callback();
            }, calculateTimeout(successMsg));
        } catch (err) {
            setError(true);
            setErrorMessage(err);
            setCurrentType('error');
            setTimeout(() => {
                setActive(false);
                setError(null);
                setCurrentType(type);
            }, calculateTimeout(err));
        } finally {
            setLoading(false);
        }
    };

    return (
        <button className={`ra-btn ${size} ${ghost ? 'ghost' : ''} ${isLink ? 'active' : ''} ${active ? 'active' : ''} ${width ? 'w-' + width : ''}`} onClick={handleClick} disabled={loading || success || error}>
            <div className={`outer ${width ? 'w-100' : ''}`}>
                <div className={`inner ${currentType}  ${width ? 'w-100' : ''}`}>
                    {loading && <FontAwesomeIcon icon={faSpinner} size="lg" spin className="ra-icon loading-icon absolute" />}

                    {error ? (
                        <div className="ra-status-message">
                            <div>
                                <FontAwesomeIcon icon={faXmark} size="xl" className={`ra-icon error-icon`} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        </div>
                    ) : null}

                    {success ? (
                        <div className="ra-status-message">
                            <div>
                                <FontAwesomeIcon icon={faCheck} size="xl" className={`ra-icon success-icon`} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: successMessage }} />
                        </div>
                    ) : null}

                    <span className="ra-btn-label">{label}</span>
                </div>
            </div>
        </button>
    );
}

export default Button;

