import React, { createContext, useState, useEffect } from 'react';
import { user as fetchUser } from './../API.jsx'; // Assuming this is your user API call

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch user data when the component mounts
        const fetchUserData = async () => {
            try {
                const data = await fetchUser();
                setUserData(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false); // Stop loading once the data is fetched
            }
        };
        
        fetchUserData();
    }, []);

    if (loading) {
        return <div className="spinner">Loading...</div>; // Replace with actual spinner component if needed
    }

    if (error) {
        return <div>Error: {error}</div>; // Handle error state
    }

    // Render children only when user data is available
    return (
        <UserContext.Provider value={{ userData }}>
            {children}
        </UserContext.Provider>
    );
};
