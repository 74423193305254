import React, { useContext , useState, useEffect } from "react";
import { UserContext } from './../../contexts/UserContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasFaHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farFaHeart } from '@fortawesome/free-regular-svg-icons';
import { save, remove } from './../../API.jsx';

function ButtonLike({ item, reloadItem, parentType }) {
    const [liked, setLiked] = useState(false);
    const [likeInteractionId, setLikeInteractionId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLiked(item.liked_by_current_user || false);
        setLikeInteractionId(item.like_interaction_id || null);
    }, [item]);

    const handleClick = async () => {
        setLoading(true);
        setError(null);
        try {
            if (!liked) {
                await save('content/interactions', { content_id: item._id, type: 'like', state: true, parent_type: parentType });
            } else {
                await save('content/interactions', { content_id: item._id, type: 'like', state: false, parent_type: parentType });
            }

            setLiked(!liked);

            if (reloadItem) reloadItem(item._id);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };


    return (
        <button className="btn rounded-pill px-2 ms-n2 me-1" onClick={handleClick} disabled={loading}>
            <div className={`d-flex align-items-center ${liked ? 'text-danger' : 'text-secondary'}`}>
                <FontAwesomeIcon icon={liked ? fasFaHeart : farFaHeart} size="1x" className="me-1" />
                <span className="text-xs">{item.likes_count}</span>
            </div>
        </button>
    );
}

export default ButtonLike;
