import React, { useContext, useState, useRef } from "react";
import { UserContext } from './../../contexts/UserContext';
import { save } from './../../API.jsx';
import DOMPurify from 'dompurify'; // For sanitizing the content

function NewComment({ item, parentType, reloadItem, addNewComment }) {
    const { userData } = useContext(UserContext);
    const contentEditableRef = useRef(null); // Ref for the editable div
    const [content, setContent] = useState('');  // State to manage the comment content (HTML)
    const [loading, setLoading] = useState(false);  // Loading state for form submission
    const [error, setError] = useState(null);  // Error state for any submission errors
    const [successMessage, setSuccessMessage] = useState(null);  // Success message after form submission
    const [isPlaceholderVisible, setPlaceholderVisible] = useState(true);  // Manage placeholder visibility

    // Handle input event for the contentEditable div
    const handleInput = () => {
        const htmlContent = contentEditableRef.current.innerHTML; // Get the inner HTML of the div
        setContent(htmlContent); // Set the HTML content in state
    };

    // Handle focus and blur to toggle placeholder
    const handleFocus = () => {
        if (isPlaceholderVisible) {
            setPlaceholderVisible(false);
            // Clear the content to allow user to type
            contentEditableRef.current.innerHTML = '';
        }

    };

    const handleBlur = () => {
        const htmlContent = contentEditableRef.current.innerHTML;
        if (!isPlaceholderVisible && htmlContent.trim() === '') {
            setPlaceholderVisible(true);
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();  // Prevent default form submission
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            // Sanitize the content before sending to the API
            const sanitizedContent = DOMPurify.sanitize(content);

            // Save the comment via the API
            const response = await save('content/interactions', {
                content_id: item._id,
                parent_type: parentType,
                type: 'comment',
                content: sanitizedContent,
                state: true
            });

            if (response) {
                setContent('');  // Clear the content state
                contentEditableRef.current.innerHTML = ''; // Clear the contentEditable div
                setPlaceholderVisible(true); // Show placeholder again
                setSuccessMessage('Kommentar erfolgreich erstellt!');
                addNewComment(response); // Pass the newly created comment to the parent
                if (reloadItem) reloadItem(item._id); // Reload the item if needed
            }
        } catch (err) {
            setError('Kommentar konnte nicht abgeschickt werden!');
        } finally {
            setLoading(false);  // Stop loading
            setPlaceholderVisible(true);
        }
    };

    return (
        <div className="row gx-0 border-gray-1">
            <div className="col">
                <form className="border-top border-1 border-gray-1" onSubmit={handleSubmit}>
                    <div className="d-flex align-items-center">
                        <div className="col">
                            <div className="d-flex py-3">
                                <div className="d-flex justify-content-center">
                                    <div style={{ width: '40px' }}>
                                        <img
                                            src={"https://i.pravatar.cc/300?" + userData._id}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                        />
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div
                                        id="content"
                                        className={`form-control ${isPlaceholderVisible ? 'text-secondary' : ''}`}
                                        contentEditable
                                        onInput={handleInput}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={contentEditableRef}
                                        style={{ minHeight: '40px' }}
                                    >
                                        {isPlaceholderVisible && 'Kommentieren...'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col pe-3">
                            <div className="row justify-content-end align-items-center">
                                {/* <div className="col-auto">
                                    {error && <span className={'text-danger'}>{error}</span>}
                                    {successMessage && <span className={'text-success'}>{successMessage}</span>}
                                </div> */}
                                <div className="col-auto">
                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {loading ? 'Sendet...' : 'Antworten'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default NewComment;
