import React from "react";
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/de';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faLink } from '@fortawesome/free-solid-svg-icons';

// Entpacken der Props, um das item zu erhalten
function FeedItemHeader({ item, type }) {
    return (
        <div className="mb-2">
            <div className="d-flex">
                <div className="text-md fw-semibold mb-0 me-2">
                    <a href="">
                        {item.user?.name}
                    </a>
                </div>
                <Link to={`/${type}/${item._id}`} className="text-secondary text-md me-2">
                    {moment(item.updated_at).fromNow()}
                </Link>
            </div>
            {item.type === "YOUTUBE.VIDEO" ? (
                <div className="d-flex align-items-center">
                    <div className="text-danger text-sm me-2">
                        <a target="_blank" href={item.data?.link} rel="noreferrer">
                            <FontAwesomeIcon className="text-danger text-sm me-1" icon={faLink} />
                            YouTube
                        </a>
                    </div>
                    <div className="text-secondary text-sm">
                        <a target="_blank" href={item.data?.link} rel="noreferrer">
                            Hat ein Video veröffentlicht.
                        </a>        
                    </div>
                </div>
            ) : item.type === "INSTAGRAM.IMAGE" ? (
                <div className="d-flex align-items-center">
                    <div className="text-danger text-sm me-2">
                        <a target="_blank" href={item.data?.media_url} rel="noreferrer">
                            <FontAwesomeIcon className="text-danger text-sm me-1" icon={faLink} />
                            Instagram
                        </a>
                    </div>
                    <div className="text-secondary text-sm">
                        <a target="_blank" href={item.data?.media_url} rel="noreferrer">
                            Hat einen Beitrag veröffentlicht.
                        </a>        
                    </div>
                </div>
            ) : item.type === "INSTAGRAM.VIDEO" ? (
                <div className="d-flex align-items-center">
                    <div className="text-danger text-sm me-2">
                        <a target="_blank" href={item.data?.media_url} rel="noreferrer">
                            <FontAwesomeIcon className="text-danger text-sm me-1" icon={faLink} />
                            Instagram
                        </a>
                    </div>
                    <div className="text-secondary text-sm">
                        <a target="_blank" href={item.data?.media_url} rel="noreferrer">
                            Hat ein Video veröffentlicht.
                        </a>        
                    </div>
                </div>
            ) : (
                <></>
            )}
            
        </div>
    );
}
export default FeedItemHeader;
