import React from "react";
import PropTypes from "prop-types";

import './Input.css';

function Input({ icon, label, name, type, value, onChange, placeholder, required, status }) {
    return (
        <div className="input-group">
            {icon && <span className="input-group-text icon">{icon}</span>}
            <div className="form-floating">
                <input
                    id={type + "-input-" + label}
                    name={name}
                    type={type}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className={`form-control form-control-sm ${status === "error" ? "is-invalid" : ""}`}
                    required={required}
                />
                {label && <label className="form-label" htmlFor={type + "-input-" + label}>{label}</label>}
            </div>
        </div>
    );
}

Input.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
};

export default Input;

