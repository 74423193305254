import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserToken } from './../API.jsx';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const token = getUserToken();

    return token ? <Component {...rest} /> : <Navigate to="/welcome" />;
};

export default ProtectedRoute;