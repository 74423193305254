import React, { useState, useEffect } from 'react';
import { get } from './../API.jsx';

import FeedItem from '../components/feed/FeedItemNew/FeedItemNew.jsx';
import Button from '../components/buttons/Button/Button.jsx';



function User() {
    const [resultData, setResultData] = useState([]);
    const endpoint = 'users/me';

    useEffect(() => {
        get(endpoint).then(data => {
            setResultData(data);
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <div className="px-3">
            <h1>Kompnentenübersicht</h1>
            <section>
                <h2>Feed Item</h2>
                <FeedItem />
            </section>
            <section>
                <h2>Buttons</h2>
                <div className="row g-3 justify-content-center align-items-center">
                    <div className="col-6">
                        <Button 
                            label="Kleiner Button" 
                            type="info" 
                            size="small" 
                            ghost={true} 
                            errorMsg="Die Daten konnten nicht gespeichert werden!" 
                            successMsg="Die Daten wurden erfolgreich gespeichert!"
                            clickTrigger={() => console.log("Triggered")} 
                            clickResponse={() => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        // Simulate random outcome: success or error
                                        if (Math.random() > 0.5) {
                                            resolve(); // Success
                                        } else {
                                            reject(new Error("Random error occurred")); // Error
                                        }
                                    }, 3000);
                                })
                            }
                        />
                    </div>
                    <div className="col-6">
                        <p>
                            Kleiner Button
                        </p>
                    </div>
                    <div className="col">
                        <Button 
                            label="Normaler Button" 
                            type="warning" 
                            ghost={true}
                            errorMsg="Die Daten konnten nicht gespeichert werden!" 
                            successMsg="Die Daten wurden erfolgreich gespeichert!"
                            clickTrigger={() => console.log("Triggered")} 
                            clickResponse={() => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        // Simulate random outcome: success or error
                                        if (Math.random() > 0.5) {
                                            resolve(); // Success
                                        } else {
                                            reject(new Error("Random error occurred")); // Error
                                        }
                                    }, 3000);
                                })
                            }
                        />
                        <p>Normaler Button
                        </p>
                    </div>
                    <div className="col">
                        <Button 
                            label="Großer Button" 
                            type="primary" 
                            size="big" 
                            ghost={true} 
                            errorMsg="Die Daten konnten nicht gespeichert werden!" 
                            successMsg="Die Daten wurden erfolgreich gespeichert!"
                            clickTrigger={() => console.log("Triggered")} 
                            clickResponse={() => 
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        // Simulate random outcome: success or error
                                        if (Math.random() > 0.5) {
                                            resolve(); // Success
                                        } else {
                                            reject(new Error("Random error occurred")); // Error
                                        }
                                    }, 3000);
                                })
                            }
                        />
                        <p>Großer Button
                        </p>
                    </div>
                    
                </div>
            </section>
            
            
        </div>
    );
}

export default User;