import React from 'react';
import { Link } from 'react-router-dom';

import './Welcome.css';

import Button from 'components/buttons/Button/Button';

function Welcome() {
    return (
        <div className="welcome">
            <div className="menu-container">
                <div className="menu">
                    <div className="row">
                        <div className="col-12">
                            <h1>Willkommen</h1>
                            <p>Melde dich mit einem bestehenden Konto an oder erstelle ein neues.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Link to={`/login`}>
                                <Button 
                                    label="Anmelden" 
                                    type="primary" 
                                    width="100"
                                    ghost={true}
                                    isLink={true} 
                                    errorMsg="Die Daten konnten nicht gespeichert werden!" 
                                    successMsg="Die Daten wurden erfolgreich gespeichert!"
                                    clickTrigger={() => console.log("Triggered")} 
                                    clickResponse={() => 
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                if (Math.random() > 0.5) {
                                                    resolve(); // Erfolg
                                                } else {
                                                    reject(new Error("Random error occurred")); // Fehler
                                                }
                                            }, 3000);
                                        })
                                    }
                                />
                            </Link>
                        </div>
                        <div className="col-12">
                            <Link to={`/register`}>
                                <Button 
                                    label="Registrieren" 
                                    type="secondary" 
                                    width="100" 
                                    isLink={true}
                                    errorMsg="Die Daten konnten nicht gespeichert werden!" 
                                    successMsg="Die Daten wurden erfolgreich gespeichert!"
                                    clickTrigger={() => console.log("Triggered")} 
                                    clickResponse={() => 
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                if (Math.random() > 0.5) {
                                                    resolve(); // Erfolg
                                                } else {
                                                    reject(new Error("Random error occurred")); // Fehler
                                                }
                                            }, 3000);
                                        })
                                    }
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;

