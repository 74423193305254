import React from "react";


function Post({ item }) {
    return (
        <div className="me-3">
            <div className="text-md lh-3 mb-2">
                {item.data?.content || item.content}
            </div>
        </div>
    );
}

export default Post;
