import React, { useState, useContext, useRef } from "react";

import { UserContext } from './../../contexts/UserContext';
import FeedItemHeader from "./FeedItemHeader.jsx";
import FeedItemAvatar from "./../../components/feed/FeedItemAvatar.jsx";
import Post from "./../../components/feed/Post.jsx";
import FeedItemInteractions from "./FeedItemInteractions.jsx";

function FeedItem({ item, reloadItem, type }) {
    const { userData } = useContext(UserContext);
    const [commentsData, setCommentsData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const elementRef = useRef(null);
    const endpoint = 'content/' + item._id + '/comments';

    const addNewComment = (newComment) => {
        if (newComment.parent_type === "post") {
            setCommentsData((prevData) => [newComment, ...prevData]);
        }
    };




    return (
        <>

            <div className="border-bottom border-1 border-gray-1" >
                <div className="row gx-0 pe-3">
                    <div className="col-auto pt-3">
                        <FeedItemAvatar isOpen={isOpen} isAnimating={isAnimating} item={item} />
                    </div>
                    <div className="col py-3">
                        <FeedItemHeader item={item} type={type} />
                        <Post item={item} />
                        <FeedItemInteractions
                            item={item}
                            reloadItem={reloadItem}
                            parentType="post"
                        />
                    </div>
                </div>

              
            </div>
        </>
    );
}

export default FeedItem;